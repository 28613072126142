import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useSearchParams = () => {
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const syncSearchParams = (_searchParams?: URLSearchParams) => {
    history.replace({ search: (_searchParams || searchParams).toString() });
  };

  const size = useMemo(() => Array.from(searchParams.entries()).length, [searchParams]);

  return { searchParams, syncSearchParams, size };
};

export default useSearchParams;
