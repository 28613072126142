import { ErrorInfo, PartialWithId } from '../../types/interfaces';
import { SetOldPricingPriceDetailsAction } from '../../components/oldPricing/oldPricing.types';

export enum DatabasesActionTypes {
  START_DATABASES_POLLING = 'START_DATABASES_POLLING',

  GET_DATABASES_REQUEST = 'GET_DATABASES_REQUEST',
  GET_DATABASES_SUCCESS = 'GET_DATABASES_SUCCESS',
  GET_DATABASES_FAILURE = 'GET_DATABASES_FAILURE',

  CREATE_UPDATE_DB_REQUEST = 'CREATE_UPDATE_DB_REQUEST',

  CREATE_DB_SUCCESS = 'CREATE_DB_SUCCESS',

  UPDATE_DB_REQUEST = 'UPDATE_DB_REQUEST',
  UPDATE_DB_SUCCESS = 'UPDATE_DB_SUCCESS',

  USER_UPDATE_SUBS_PRICE_CONFIRMATION = 'USER_UPDATE_SUBS_PRICE_CONFIRMATION',

  SET_NEW_PRICE_DATA = 'SET_NEW_PRICE_DATA',
  SET_NEW_DB_RCP_PLAN = 'SET_NEW_DB_RCP_PLAN',
  SET_SHOULD_SHOW_CLUSTERING_DIALOG = 'SET_SHOULD_SHOW_CLUSTERING_DIALOG',
  USER_CLUSTERING_CONFIRMATION = 'USER_CLUSTERING_CONFIRMATION',
  SET_SHOULD_SHOW_OPTIMIZATION_DIALOG = 'SET_SHOULD_SHOW_OPTIMIZATION_DIALOG',
  USER_BDB_OPTIMIZATION_CONFIRMATION = 'USER_BDB_OPTIMIZATION_CONFIRMATION',
  CANCEL_CREATE_UPDATE_DB = 'CANCEL_CREATE_UPDATE_DB',

  DELETE_DB_SUCCESS = 'DELETE_DB_SUCCESS',

  GET_BDB_SYNC_SOURCES_REQUEST = 'GET_BDB_SYNC_SOURCES_REQUEST',
  GET_BDB_SYNC_SOURCES_SUCCESS = 'GET_BDB_SYNC_SOURCES_SUCCESS',
  GET_BDB_SYNC_SOURCES_FAILURE = 'GET_BDB_SYNC_SOURCES_FAILURE',

  VALIDATE_BDB_SYNC_SOURCES_REQUEST = 'VALIDATE_BDB_SYNC_SOURCES_REQUEST',
  VALIDATE_BDB_SYNC_SOURCES_SUCCESS = 'VALIDATE_BDB_SYNC_SOURCES_SUCCESS',
  VALIDATE_BDB_SYNC_SOURCES_FAILURE = 'VALIDATE_BDB_SYNC_SOURCES_FAILURE',

  RESET_DBD_VALIDATION_STATE = 'RESET_DBD_VALIDATION_STATE',

  BACKUP_DB_REQUEST = 'BACKUP_DB_REQUEST',
  BACKUP_DB_SUCCESS = 'BACKUP_DB_SUCCESS',
  BACKUP_DB_FAILURE = 'BACKUP_DB_FAILURE',

  IMPORT_DB_REQUEST = 'IMPORT_DB_REQUEST',
  IMPORT_DB_SUCCESS = 'IMPORT_DB_SUCCESS',
  IMPORT_DB_FAILURE = 'IMPORT_DB_FAILURE',

  REFRESH_DB_SUCCESS = 'REFRESH_DB_SUCCESS',
  RESET_IMPORT_DB_STATE = 'RESET_IMPORT_DB_STATE',

  SET_DB_TABLE_PAGE_SIZE = 'SET_DB_TABLE_PAGE_SIZE',

  SET_SHOW_REDIS_STACK_CONGRATULATIONS = 'SET_SHOW_REDIS_STACK_CONGRATULATIONS',
  SET_DBS_STATUS = 'SET_DBS_STATUS'
}

export interface StartDatabasesPollingRequestAction {
  type: DatabasesActionTypes.START_DATABASES_POLLING;
  payload: boolean;
}

export interface GetDatabasesRequestAction {
  type: DatabasesActionTypes.GET_DATABASES_REQUEST;
  payload: boolean;
}

export interface GetDatabasesSuccessAction {
  type: DatabasesActionTypes.GET_DATABASES_SUCCESS;
  payload: BdbById;
}

export interface GetDatabasesFailureAction {
  type: DatabasesActionTypes.GET_DATABASES_FAILURE;
  payload: string;
}

export interface CreateUpdateDatabaseRequestAction {
  type: DatabasesActionTypes.CREATE_UPDATE_DB_REQUEST;
  payload: CreateUpdateDatabaseRequestPayload;
}

export interface SetShouldShowClusteringDialogAction {
  type: DatabasesActionTypes.SET_SHOULD_SHOW_CLUSTERING_DIALOG;
}

export interface SetShouldShowBdbOptimizationDialogAction {
  type: DatabasesActionTypes.SET_SHOULD_SHOW_OPTIMIZATION_DIALOG;
}

export interface UserClusteringConfirmationAction {
  type: DatabasesActionTypes.USER_CLUSTERING_CONFIRMATION;
}
export interface UserBdbOptimizationAction {
  type: DatabasesActionTypes.USER_BDB_OPTIMIZATION_CONFIRMATION;
}

export interface SetDbsStatusAction {
  type: DatabasesActionTypes.SET_DBS_STATUS;
  payload: ReducerStateStatus;
}

export interface SetNewPriceDataAction {
  type: DatabasesActionTypes.SET_NEW_PRICE_DATA;
  payload: NewPriceData;
}

export interface UpdateDbPayload {
  updatedBdb: Partial<Bdb>;
  successCb(bdb?: Bdb): void;
  errorCb(errorInfo?: ErrorInfo): void;
}

export interface UpdateDbRequestAction {
  type: DatabasesActionTypes.UPDATE_DB_REQUEST;
  payload: UpdateDbPayload;
}

export interface CreateDbSuccessAction {
  type: DatabasesActionTypes.CREATE_DB_SUCCESS;
  payload: Bdb;
}

export interface UpdateDbSuccessAction {
  type: DatabasesActionTypes.UPDATE_DB_SUCCESS;
  payload: Bdb;
}

export interface RcpUpdatePayload {
  updatedRcp: SubsRcp;
  isCreateMode: boolean;
  shardTypePricingPostBody: CalculatorPayload;
  selectedSubscription: Subscription;
  setSubmitting: (isSubmitting: boolean) => void;
  selectedDatabase: Bdb;
  shardTypes: ShardType[];
  enableGetBdbsOptimizedQuery?: boolean;
}
export interface CreateUpdateDatabaseRequestPayload
  extends Partial<RcpUpdatePayload>,
    UpdateDbPayload {
  shouldUpdateRcp: boolean;
  enableGetBdbsOptimizedQuery: boolean;
}

export interface UserUpdateSubsPriceConfirmationAction {
  type: DatabasesActionTypes.USER_UPDATE_SUBS_PRICE_CONFIRMATION;
  payload: boolean;
}

export interface SetNewDbRcpPlanAction {
  type: DatabasesActionTypes.SET_NEW_DB_RCP_PLAN;
  payload: RcpPlanStatusResponse;
}

export interface CancelCreateUpdateDbAction {
  type: DatabasesActionTypes.CANCEL_CREATE_UPDATE_DB;
}

export interface DeleteDatabaseSuccessAction {
  type: DatabasesActionTypes.DELETE_DB_SUCCESS;
  payload: Bdb;
}

export interface GetBdbSyncSourcesRequestAction {
  type: DatabasesActionTypes.GET_BDB_SYNC_SOURCES_REQUEST;
  payload: string;
}
export interface GetBdbSyncSourcesSuccessAction {
  type: DatabasesActionTypes.GET_BDB_SYNC_SOURCES_SUCCESS;
  payload: ReplicaItem[];
}
export interface GetBdbSyncSourcesFailureAction {
  type: DatabasesActionTypes.GET_BDB_SYNC_SOURCES_FAILURE;
  payload: string;
}

export interface ValidateBdbSyncSourcesRequestAction {
  type: DatabasesActionTypes.VALIDATE_BDB_SYNC_SOURCES_REQUEST;
  payload: ValidateBdbSyncSourcesRequestActionPayload;
}
export interface ValidateBdbSyncSourcesSuccessAction {
  type: DatabasesActionTypes.VALIDATE_BDB_SYNC_SOURCES_SUCCESS;
}
export interface ValidateBdbSyncSourcesFailureAction {
  type: DatabasesActionTypes.VALIDATE_BDB_SYNC_SOURCES_FAILURE;
  payload: string;
}

export interface RefreshDbSuccessAction {
  type: DatabasesActionTypes.REFRESH_DB_SUCCESS;
  payload: PartialWithId<Bdb>;
}

export interface BackupDbRequestAction {
  type: DatabasesActionTypes.BACKUP_DB_REQUEST;
  payload: number;
}

export interface BackupDbSuccessAction {
  type: DatabasesActionTypes.BACKUP_DB_SUCCESS;
}

export interface BackupDbFailureAction {
  type: DatabasesActionTypes.BACKUP_DB_FAILURE;
}

export interface ImportDbRequestAction {
  type: DatabasesActionTypes.IMPORT_DB_REQUEST;
  payload: ImportDbRequestPayload;
}

export interface ImportDbSuccessAction {
  type: DatabasesActionTypes.IMPORT_DB_SUCCESS;
}

export interface ImportDbFailureAction {
  type: DatabasesActionTypes.IMPORT_DB_FAILURE;
  payload: string;
}

export interface ResetImportDbStateAction {
  type: DatabasesActionTypes.RESET_IMPORT_DB_STATE;
}
export interface ResetBdbValidationStateAction {
  type: DatabasesActionTypes.RESET_DBD_VALIDATION_STATE;
}

export interface SetDBtablePageSizeAction {
  type: DatabasesActionTypes.SET_DB_TABLE_PAGE_SIZE;
  payload: number;
}

export interface SetShowRedisStackCongratulationsAction {
  type: DatabasesActionTypes.SET_SHOW_REDIS_STACK_CONGRATULATIONS;
  payload: boolean;
}

export type DatabasesTypes =
  | StartDatabasesPollingRequestAction
  | GetDatabasesRequestAction
  | GetDatabasesSuccessAction
  | GetDatabasesFailureAction
  | GetBdbSyncSourcesRequestAction
  | GetBdbSyncSourcesSuccessAction
  | DeleteDatabaseSuccessAction
  | GetBdbSyncSourcesFailureAction
  | ValidateBdbSyncSourcesRequestAction
  | ValidateBdbSyncSourcesSuccessAction
  | ValidateBdbSyncSourcesFailureAction
  | ResetBdbValidationStateAction
  | RefreshDbSuccessAction
  | BackupDbRequestAction
  | BackupDbSuccessAction
  | SetDbsStatusAction
  | BackupDbFailureAction
  | ImportDbRequestAction
  | ImportDbSuccessAction
  | ImportDbFailureAction
  | ResetImportDbStateAction
  | SetNewPriceDataAction
  | SetNewDbRcpPlanAction
  | UserUpdateSubsPriceConfirmationAction
  | CancelCreateUpdateDbAction
  | SetOldPricingPriceDetailsAction
  | SetShouldShowClusteringDialogAction
  | SetShouldShowBdbOptimizationDialogAction
  | UserClusteringConfirmationAction
  | UserBdbOptimizationAction
  | SetDBtablePageSizeAction
  | UpdateDbSuccessAction
  | CreateDbSuccessAction
  | SetShowRedisStackCongratulationsAction;

export type BdbById = Record<string, Bdb>;
export interface BdbState extends BaseState {
  data: BdbById;
}

export interface CrdbInstance {
  bdb_id?: number;
  region_id: number;
  max_reads: number;
  max_writes: number;
  quantity: number;
  shards_count: number;
  backup: boolean;
  backup_path?: string;
  backup_interval: number | null;
  backup_interval_offset: number | null;
  backup_status: BdbBackupStatus;
  backup_failure_reason: BackupFailureReason;
  backup_progress: number;
  last_backup_time: string | null;
  replication: boolean;
  access_control?: AccessControl;
  oss_cluster: boolean;
  oss_cluster_api_preferred_ip_type: OSSClusterPolicy;
  usage: number;
  status: BdbStatuses;
  data_persistence: DataPersistence;
  bdb_alerts: DbAlert[];
  resp3?: boolean | null;
  cluster?: Cluster;
}

export interface CrdbDefaultConfig {
  sharding: boolean;
  shards_count: number | null;
  data_persistence: DataPersistence;
  backup: boolean;
  backup_path: string | null;
  access_control: AccessControl;
  bdb_alerts?: DbAlert[];
  oss_cluster: boolean;
  oss_cluster_api_preferred_ip_type: OSSClusterPolicy;
  replication: boolean;
  eviction_policy?: EvictionPolicy;
  compression?: number;
  module_list: BdbModule[];
  redis_version?: string;
}

export interface CrdbImportStatus {
  bdb_import_id: number;
  import_failure_reason: ImportFailureReason;
  import_failure_reason_params: ImportFailureReasonParam[];
  import_progress: number;
  import_status: BdbImportStatus;
}

export type CrdbStatus =
  | 'pending'
  | 'active'
  | 'active-change-pending'
  | 'delete-queued'
  | 'deleting'
  | 'deleted'
  | 'delete-pending'
  | 'error';

export interface Crdb {
  causal_consistency: boolean;
  crdb_instances: CrdbInstance[];
  default_db_config: CrdbDefaultConfig;
  id: number;
  guid?: string;
  memory_size_in_mb: number;
  name: string;
  shard_key_regex: RegexRule[];
  status: CrdbStatus;
  import_status: CrdbImportStatus;
  sharding_type: ShardingType;
  quantity: number;
  creation_date: string;
  last_changed_date: string;
  subscription?: number;
  port?: number | null;
  is_name_change_supported: boolean;
  bdbActions?: string;
  preferred_proxy_policy?: ProxyPolicy;
}

export interface BdbSyncSourcesState extends BaseState {
  data: ReplicaItem[];
}

export interface ValidateBdbSyncSourcesRequestActionPayload {
  requestData: ValidateSyncSourceRequestData;
  cb: () => void;
}

export interface NewPriceDialogRow {
  id: string;
  shardType: string;
  shardPrice: number;
  existingQty: number;
  requiredQty: number;
  totalShardPrice: number;
}

export interface NewPriceData {
  newPriceTableRows: NewPriceDialogRow[];
  currentPrice: number;
  newPrice: number;
  isPriceHigher: boolean;
}
