import useSearchParams from 'hooks/useSearchParams';

import {
  DB_TABLE_PERSISTENCE_SEARCH,
  DB_TABLE_PERSISTENCE_FILTERS,
  DB_TABLE_PERSISTENCE_SORTING
} from '../../../../../constants/databases.constants';

const sessionStorageKeys = [
  DB_TABLE_PERSISTENCE_SEARCH,
  DB_TABLE_PERSISTENCE_FILTERS,
  DB_TABLE_PERSISTENCE_SORTING
];

const useClearDbTablePersistence = () => {
  const { searchParams, syncSearchParams } = useSearchParams();

  const clearTablePersistence = () => {
    sessionStorageKeys.forEach((key) => sessionStorage.removeItem(key));
    searchParams.forEach((_, key) => searchParams.delete(key));
    syncSearchParams();
  };

  return {
    clearTablePersistence
  };
};

export default useClearDbTablePersistence;
