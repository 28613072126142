/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
export const EmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\+\-\"\' ,.<=>\-:;/?\\\[\]\{|\}`~])[A-Za-z\d!@#$%^&*()_\+\-\"\' ,.<=>\-:;/?\\\[\]\{|\}`~]/;

export const herokuNameRegex = /^[a-zA-Z0-9]([a-zA-Z0-9\-. ])*$/;

export const nameRegex =
  /^([A-Za-zÀ-ÖØ-öø-ÿ][A-Za-zÀ-ÖØ-öø-ÿ-\'\\.]* )*[A-Za-zÀ-ÖØ-öø-ÿ][A-Za-zÀ-ÖØ-öø-ÿ-\'\\.]*$/;
