import styled from 'styled-components/macro';

export const ToggleContainer = styled.div<{
  disabled: boolean;
}>`
  display: flex;
  align-items: center;

  label {
    background-color: transparent;
  }

  div + label {
    color: ${({ theme }) => theme.semantic.color.text.neutral800};
  }
`;

export const ToggleStateIndicator = styled.label<{ disabled: boolean }>`
  margin-left: 0.4rem;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
