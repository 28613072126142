import { HTMLAttributes, useState } from 'react';
import { PopperProvider } from '@redislabsdev/redis-ui-components';

export const CollisionBoundary = (props: HTMLAttributes<HTMLDivElement>) => {
  const [boundary, setBoundary] = useState<HTMLDivElement | null>(null);

  return (
    <PopperProvider collisionBoundary={boundary}>
      <div {...props} ref={setBoundary} />
    </PopperProvider>
  );
};
