import {
  CsvExportRow,
  SubscriptionType
} from 'screens/SubscriptionsAndDatabases/utils/sharedBdbTableTypes';

interface DatabaseCsvHeader {
  label: string;
  key: keyof CsvExportRow;
}

export const databasesCsvHeaders: DatabaseCsvHeader[] = [
  { label: 'Status', key: 'status' },
  { label: 'Database ID', key: 'id' },
  { label: 'Database name', key: 'name' },
  { label: 'Subscription ID', key: 'subscriptionId' },
  { label: 'Subscription name', key: 'subscriptionName' },
  { label: 'Subscription type', key: 'subscriptionType' },
  { label: 'Region', key: 'region' },
  { label: 'Public endpoint', key: 'dnsMasterPublic' },
  { label: 'Private endpoint', key: 'dnsMasterPrivate' },
  { label: 'Memory limit (MB)', key: 'memoryLimit' },
  { label: 'Memory used (MB)', key: 'memoryUsed' },
  { label: 'Throughput (Ops/sec)', key: 'throughput' },
  { label: 'Capabilities', key: 'modules' },
  { label: 'Options', key: 'dbOptions' },
  { label: 'Shard type', key: 'shardTypeName' },
  { label: 'Shard count', key: 'shardsCount' },
  { label: 'Shard price ($/hr)', key: 'shardPrice' },
  { label: 'Database price ($/hr)', key: 'totalShardsPrice' }
];

export const DB_TABLE_PERSISTENCE = 'dbTablePersistence';
export const DB_TABLE_PERSISTENCE_SEARCH = `${DB_TABLE_PERSISTENCE}-search`;
export const DB_TABLE_PERSISTENCE_FILTERS = `${DB_TABLE_PERSISTENCE}-filters`;
export const DB_TABLE_PERSISTENCE_SORTING = `${DB_TABLE_PERSISTENCE}-sorting`;

export const subscriptionsTypesMap: Record<SubscriptionType, string> = {
  essentials: 'Essentials',
  redisFlex: 'Redis Flex',
  pro: 'Pro'
};
